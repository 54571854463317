import { SEO } from "gatsby-theme-core";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@lendex/helper/methods";
import { Layout, HeaderOne, FooterOne } from "gatsby-theme-layout";
import { FAQOne, PageHeader01 } from "gatsby-theme-container";

const FAQPage = ({ data }) => {
    const content = normalizedData(data?.page?.content || []);
    return (
        <Layout>
            <SEO pageName="FAQ" />
            <HeaderOne data={{ ...data.header, ...data.navigation }} />
            <PageHeader01 data={content?.["page-header-section"]} />
            <FAQOne data={content?.["faq-section"]} />
            <FooterOne data={{ ...data.footer, ...data?.contact }} />
        </Layout>
    );
};
export const query = graphql`
    query FAQPageQuery {
        header: general(section: { eq: "header-01" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu" }) {
            ...Menu01
        }
        footer: general(section: { eq: "footer-01" }) {
            ...Footer01
        }
        contact: general(section: { eq: "contact" }) {
            ...Contact01
        }
        page(title: { eq: "faq" }) {
            content {
                ...PageContent
            }
        }
    }
`;

FAQPage.propTypes = {
    data: PropTypes.shape({
        header: PropTypes.shape({}),
        navigation: PropTypes.shape({}),
        footer: PropTypes.shape({}),
        contact: PropTypes.shape({}),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export default FAQPage;
